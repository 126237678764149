<template>
  <div>
    <label>Organization<span style="color: red;">*</span></label>
    <select class="form-select" v-model="selectedOrganization" @change="updateOrganizationalOrganization($event)" :disabled="selectDisabled" style="height: 41px;">
      <option :value="-1">Select an Educational Organization</option>
      <option v-for="organization in organizations" :key="organization" :value="organization.idOutStateOrganization">
        {{ organization.organizationName }}
      </option>
    </select>
  </div>
</template>

<script>
import {onBeforeMount, ref, watch} from "vue";
import {API_URL} from "../../../../Constants";
import axios from "axios";

export default {
  name: "ParticipatingOutstateOrganizations",
  props: {
    countryCode: String,
    stateCode: String,
    idOrganization: Number,
    selectDisabled: Boolean
  },
  setup(props, context) {
    const organizations = ref([]);
    const selectedOrganization = ref(-1);

    onBeforeMount(() => {
      getOrganizations();
      if (props.idOrganization) selectedOrganization.value = props.idOrganization;
    })

    watch(() => props.countryCode, () => {
      getOrganizations();
    })

    watch(() => props.stateCode, () => {
      getOrganizations();
    })

    watch(() => props.idOrganization, () => {
      selectedOrganization.value = props.idOrganization;
    })


    async function getOrganizations() {
      let get_uri = API_URL + "/outNeOrganization/";

      if (props.countryCode !== 'US') {
        get_uri += "getByCountry?_countryCode=" + props.countryCode;
      } else {
        get_uri += "getByState?_stateShort=" + props.stateCode;
      }

      await axios.get(get_uri)
          .then((result) => {
            organizations.value = result.data.sort((a, b) => a.organizationName > b.organizationName ? 1 : -1);
          }).finally(() => {
            selectedOrganization.value = props.idOrganization ? props.idOrganization : -1;
          })
    }

    function updateOrganizationalOrganization(event) {
      selectedOrganization.value = parseInt(event.target.value);
      context.emit("selectedOrganization", selectedOrganization.value);
    }

    return {
      organizations,
      selectedOrganization,
      updateOrganizationalOrganization
    }
  }
}
</script>

<style scoped>

</style>