<template>
  <div class="row">
    <div class="col-sm">
      <ParticipatingCountries
          @countryCode="updateCountry($event)"
          :show-all-select-value="false"
          :default-country="selectedCountry"
          :select-disabled="countryDisabled"
      />
    </div>
    <div class="col-sm">
      <div v-if="selectedCountry === 'US'">
        <ParticipatingStates
            @selectedState="updateState($event)"
            :state-code="selectedState"
            :select-disabled="stateDisabled"
        />
      </div>
    </div>
    <div class="col-sm">
      <div v-if="showNebraskaHierarchy">
        <ParticipatingEsus
            @selectedEsu="updateEsu($event)"
            :id-esu="selectedEsu"
            :select-disabled="esuDisabled"
        />
      </div>
      <div v-else-if="showOutstateDropdown">
        <ParticipatingOutstateOrganizations
            :country-code="selectedCountry"
            :state-code="selectedState"
            @selectedOrganization="updateOutstateOrganization($event)"
            :select-disabled="outstateOrgDisabled"
            :id-organization="selectedOutStateOrg"
        />
      </div>
    </div>
    <div class="col-sm">
      <div v-if="selectedEsu !== -1">
        <ParticipatingDistricts
            :id-esu="selectedEsu"
            @selectedDistrict="updateDistrict($event)"
            :id-district="selectedDistrict"
            :select-disabled="districtDisabled"
        />
      </div>
    </div>
    <div class="col-sm">
      <div v-if="selectedDistrict !== -1">
        <ParticipatingSchools
            :id-district="selectedDistrict"
            :id-esu="selectedEsu"
            @selectedSchool="updateSchool($event)"
            :select-disabled="schoolDisabled"
            :id-school="selectedSchool"
        />
      </div>
    </div>

  </div>

  <hr/>
  <OrganizationHierarchy
      :country-code="selectedCountry"
      :state-code="selectedState"
      :id-esu="selectedEsu"
      :id-district="selectedDistrict"
      :id-school="selectedSchool"
      :id-outstate-org="selectedOutStateOrg"
  />

</template>

<script>
import {computed, onBeforeMount, ref, watch} from 'vue';
import ParticipatingEsus from "@/components/Reporting/OrganizationDropdowns/ParticipatingEsus";
import ParticipatingDistricts from "@/components/Reporting/OrganizationDropdowns/ParticipatingDistricts";
import ParticipatingSchools from "@/components/Reporting/OrganizationDropdowns/ParticipatingSchools";
import ParticipatingCountries from "@/components/Reporting/OrganizationDropdowns/ParticipatingCountries";
import ParticipatingOutstateOrganizations
  from "@/components/Reporting/OrganizationDropdowns/ParticipatingOutstateOrganizations";

import ParticipatingStates from "@/components/Reporting/OrganizationDropdowns/ParticipatingStates";
import OrganizationHierarchy from "@/components/UserOrganizationHierarchy/OrganizationHierarchy";

export default {
  name: "OrganizationHierarchyAdminView",
  components: {
    OrganizationHierarchy,
    ParticipatingOutstateOrganizations,
    ParticipatingStates,
    ParticipatingCountries,
    ParticipatingSchools,
    ParticipatingDistricts,
    ParticipatingEsus,
  },
  props: {
    countryCode: String,
    stateCode: String,
    idOutstateOrg: Number,
    idEsu: Number,
    idDistrict: Number,
    idSchool: Number,
    countryDisabled: Boolean,
    stateDisabled: Boolean,
    outstateOrgDisabled: Boolean,
    esuDisabled: Boolean,
    districtDisabled: Boolean,
    schoolDisabled: Boolean,

    hideDropdowns: Boolean
  },
  setup(props) {
    const selectedEsu = ref(-1);
    const selectedDistrict = ref(-1);
    const selectedSchool = ref(-1);
    const selectedState = ref("-1");
    const selectedCountry = ref("-1");
    const selectedOutStateOrg = ref(-1);

    onBeforeMount(() => {
      if (props.countryCode) selectedCountry.value = props.countryCode;
      if (props.stateCode) selectedState.value = props.stateCode;
      if (props.idOutstateOrg) selectedOutStateOrg.value = props.idOutstateOrg;
      if (props.idEsu) selectedEsu.value = props.idEsu;
      if (props.idDistrict) selectedDistrict.value = props.idDistrict;
      if (props.idSchool) selectedSchool.value = props.idSchool;
    })

    function updateEsu(event) {
      selectedEsu.value = event;
      updateDistrict(-1);
    }

    function updateDistrict(event) {
      selectedDistrict.value = event;
      updateSchool(-1);
    }

    function updateSchool(event) {
      selectedSchool.value = event;
      updateOutstateOrganization(-1);
    }

    function updateState(event) {
      selectedState.value = event;
      updateEsu(-1);
    }

    function updateCountry(event) {
      selectedCountry.value = event;
      selectedState.value = "-1";
      updateOutstateOrganization(-1);
    }

    function updateOutstateOrganization(event) {
      selectedOutStateOrg.value = event;
    }

    const showNebraskaHierarchy = computed(() => {
      return selectedState.value === 'NE';
    })

    const showOutstateDropdown = computed(() => {
      if (selectedCountry.value !== 'US') {
        return selectedCountry.value !== "-1";
      }
      return selectedState.value !== "-1";
    })

    watch(() => props.countryCode, () => {
      selectedCountry.value = props.countryCode;
    })

    watch(() => props.stateCode, () => {
      selectedState.value = props.stateCode;
    })

    watch(() => props.idEsu, () => {
      selectedEsu.value = props.idEsu;
    })

    watch(() => props.idDistrict, () => {
      selectedDistrict.value = props.idDistrict;
    })

    watch(() => props.idSchool, () => {
      selectedSchool.value = props.idSchool;
    })

    watch(() => props.idOutstateOrg, () => {
      selectedOutStateOrg.value = props.idOutstateOrg;
    })

    return {
      selectedEsu,
      selectedDistrict,
      selectedSchool,
      selectedState,
      selectedCountry,
      selectedOutStateOrg,
      showNebraskaHierarchy,
      showOutstateDropdown,
      updateEsu,
      updateDistrict,
      updateSchool,
      updateState,
      updateCountry,
      updateOutstateOrganization
    }
  }
}
</script>

<style scoped>

</style>